@use "styles/color2" as color;
@import "styles/theme.scss";
@import "styles/bootstrap.scss";

@import "styles/variables.scss";
@import "styles/components/mixins/tab-group.scss";
@import "styles/components/tab-group.scss";
@import "styles/components/mixins/timeline.scss";
@import "styles/components/timeline.scss";

body {
  margin: 0;
  padding: 56px 0 0 0;
  height: 100%;
  background-color: color.$rgba-white-0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

input[type=file] {
  width: 100%;
}

.navbar {
  border-color: color.$rgba-grey-2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.text-navy {
  color: color.$rgba-tertiary-0;
}

.dropdown a{
  color: color.$rgba-tertiary-0!important;
}

.dropdown a:hover {
  color: color.$rgba-tertiary-1!important;
}

.btn-link {
  color: color.$rgba-grey-1;
}
.btn-link:hover {
  color: color.$rgba-grey-1;
}

.text-primary-0 {
  color: color.$rgba-primary-0;
}

.text-secondary-1-0 {
  color: color.$rgba-secondary-1-0;
}

.text-secondary-1-0 {
  color: color.$rgba-secondary-1-0;
}
.text-complement-0 {
  color: color.$rgba-complement-0;
}

.container-no-color {
  background-color: transparent;
}

.container-navy {
  background-color: color.$rgba-tertiary-0;
  color: color.$rgba-white-0;
}

.container-primary-0 {
  background-color: color.$rgba-primary-0;
  color: color.$rgba-white-0;
}

.container-outline-primary-0 {
  background-color: color.$rgba-white-0;
  border-color: color.$rgba-primary-0;
  color: color.$rgba-primary-0;
}

.container-secondary-1-0 {
  background-color: color.$rgba-secondary-1-0;
  color: color.$rgba-white-0;
}

.container-secondary-1-0 {
  background-color: color.$rgba-secondary-1-0;
  color: color.$rgba-white-0;
}

.container-complement-0 {
  background-color: color.$rgba-complement-0;
  color: color.$rgba-white-0;

}.container-primary-4 {
  background-color: color.$rgba-primary-4;
  color: color.$rgba-white-0;
}

.container-secondary-1-4 {
  background-color: color.$rgba-secondary-1-4;
  color: color.$rgba-white-0;
}

.container-secondary-2-2 {
  background-color: color.$rgba-secondary-2-2;
  color: color.$rgba-white-0;
}

.container-outline-secondary-2-2 {
  background-color: color.$rgba-white-0;
  border-color: color.$rgba-secondary-2-2;
  color: color.$rgba-secondary-2-2;
}

.container-complement-4 {
  background-color: color.$rgba-complement-4;
  color: color.$rgba-white-0;
}

.container-grey-0 {
  background-color: color.$rgba-grey-0;
  color: color.$rgba-white-0;
}

.container-grey-1 {
  background-color: color.$rgba-grey-1;
  color: color.$rgba-white-0;
}

.container-white-0 {
  background-color: color.$rgba-white-0;
}

.box-shadow-1 {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
}

.box-shadow-2 {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.05);
}

.full-width-container {
  max-width: 100%;
}

.full-height-container {
  min-height: calc(100vh - 112px);
}

.full-window-container {
  padding: 0;
  margin: 0;
  height: calc(100% - 63px);
  width: 100%;
  position: fixed;
  left: 0px;
  top: 63px;
  z-index: 100;
}

.full-viewport-container {
  @extend .full-height-container;
  @extend .full-height-container;
  position: absolute;
}

.hidden {
  display: none;
}

.list-style-none {
  list-style-type: none;
  padding: 0px;
}

.clear-table td, .clear-table th, .clear-table thead th{
  border-bottom: none;
  border-top: none;
}

.clear-table .highlight1, .clear-table .highlight1 {
  color: color.$rgba-secondary-2-2;
  border-left: 1px solid color.$rgba-secondary-2-2;
  border-right: 1px solid color.$rgba-secondary-2-2;
}
.clear-table .highlight2, .clear-table .highlight2 {
  color: color.$rgba-primary-0;
  border-left: 1px solid color.$rgba-primary-0;
  border-right: 1px solid color.$rgba-primary-0;
}
.draggable {
  cursor: move;
}

.blinking-slow {
  animation: blinker 1s linear infinite;
}
.blinking-fast {
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.no-pointer-events {
  pointer-events: none;
}

.show {
  opacity: 1;
  transition: opacity 0.5s;
}
.hide {
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}

video {
  width: 100%;
  height: 100%;
  display: flex;
}

.blur {
  filter: blur(1rem);
}

.text-overlap {
  letter-spacing: -0.1rem;
}