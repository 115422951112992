@use "color2" as color;
@use "sass:math";

//https://stackoverflow.com/questions/55593229/increasing-the-size-of-a-bootstrap-checkbox
@mixin switch($res: 'sm') {
    $index: 1rem;
    $mainVal: 1rem;
  
    @if $res == 'md' {
        $index: 2rem;
        $mainVal: 1.5rem;
    } @else if $res == 'lg' {
        $index: 3rem;
        $mainVal: 2rem;
    } @else if $res == 'xl' {
        $index: 4rem;
        $mainVal: 2.5rem;
    }

    margin-bottom: math.div($index, 2); /* JUST FOR STYLING PURPOSE */

    .form-check-input {
        height: $mainVal;
        width: calc(#{$index} + 0.75rem);
        border-radius: $mainVal * 2;
    }
}

.form-check-input {
  clear: left;
}

// YOU CAN PUT ALL RESOLUTION HERE
// sm - DEFAULT, md, lg, xl

.form-switch.form-switch-sm {
    @include switch();
}

.form-switch.form-switch-md {
    @include switch('md');
}

.form-switch.form-switch-lg {
    @include switch('lg');
}

.form-switch.form-switch-xl {
    @include switch('xl');
}

.custom-control-input-prime:checked~.custom-control-label::before {
    background-color: color.$rgba-primary-0;
    border-color: color.$rgba-primary-0;
}

.custom-control-input-complement:checked~.custom-control-label::before {
    background-color: color.$rgba-complement-0;
    border-color: color.$rgba-complement-0;
}

.custom-control-input-secondary1:checked~.custom-control-label::before {
    background-color: color.$rgba-secondary-1-0;
    border-color: color.$rgba-secondary-1-0;
}

.custom-control-input-secondary2:checked~.custom-control-label::before {
    background-color: color.$rgba-secondary-2-2;
    border-color: color.$rgba-secondary-2-2;
}