@use "color2" as color;
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&family=Josefin+Sans:wght@100;300&family=Montserrat&display=swap');

body {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    color: color.$rgba-grey-1;
}

.pitchli-text {
    font-size: 1.5rem;
    font-family: 'Comfortaa', 'Verdana', sans-serif;
    color: color.$rgba-tertiary-0;
    font-weight: 700;
}

h1, h2, h3 {
    font-family: 'Comfortaa', 'Verdana', sans-serif;
    color: color.$rgba-tertiary-0;
}

h1 {
    font-weight: 500;
}

h2 {
    font-weight: 400;
}

h3 {
    font-weight: 300;
}

h4, h5 {
    font-family: 'Josefin', sans-serif;
    color: color.$rgba-tertiary-0;
}

h4 {
    font-weight: 200;
}

h5 {
    font-weight: 100;
}

a {
    color: color.$rgba-secondary-2-2;
}
a:hover {
    color: color.$rgba-secondary-2-0;
}

.link-white{
    color: color.$rgba-grey-0;
}
.link-white:hover {
    color: color.$rgba-white-0;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-complement {
    color: color.$rgba-complement-0;
}

select.form-control,
textarea.form-control,
input.form-control {
    font-size: 1rem;
}

.text-secondary-1{
    color: color.$rgba-secondary-1-0;
}

.text-secondary-2{
    color: color.$rgba-secondary-2-2;
}

.text-prime{
    color: color.$rgba-primary-0;
}

.text-fineprint {
    font-size: 0.8rem;
}

.display-linebreak {
    white-space: pre-line;
}

.tooltip-text {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/*   font-family: 'Comfortaa', cursive;
font-family: 'Josefin Sans', sans-serif;
font-family: 'Montserrat', sans-serif; */


//LISTS
ol.contract-list {
    margin: 0;
    padding: 0;
  }
ol.contract-list:not([type="a"]):not([type="i"]) {
  list-style-type: none;
  counter-reset: item;
}
ol.contract-list[type="a"] {
  list-style-type: none;
  counter-reset: alpha;
}
ol.contract-list[type="i"] {
  list-style-type: none;
  counter-reset: roman;
}

ol.contract-list > li {
  display: table;
  margin-bottom: 0.6em;
}

ol.contract-list:not([type="a"]):not([type="i"]) > li {
  counter-increment: item;
}

ol.contract-list > li:before {
  display: table-cell;
  padding-right: 0.6em;
}

ol.contract-list > li {
  font-family: 'Josefin', sans-serif;
  color: color.$rgba-tertiary-0;
  font-weight: 200;
  font-size: 1.5rem;
}
ol.contract-list> li li {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  color: color.$rgba-grey-1;
}

ol.contract-list:not([type="a"]):not([type="i"]) > li:before {
  content: counters(item, ".") ". ";
}
ol.contract-list[type="a"] > li:before {
  counter-increment: alpha;
  content: "(" counter(alpha, lower-alpha) ") ";
}
ol.contract-list[type="i"] > li:before {
  counter-increment: roman;
  content: "(" counter(roman, lower-roman) ") ";
}

li ol.contract-list > li {
  margin: 0;
}

li ol.contract-list:not([type="a"]):not([type="i"]) > li:before {
  content: counters(item, ".") " ";
}