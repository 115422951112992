@use "color2" as color;
@use "bootstrap_defaults" as bsDefaults;

$false: false;

$theme-colors: (
    "primary": color.$rgba-primary-0,
    "complement": color.$rgba-secondary-2-2,
    "success": color.$rgba-complement-0,
    "warning": color.$rgba-secondary-1-0,
    "secondary": bsDefaults.$secondary,
    "info": bsDefaults.$info,
    "danger": bsDefaults.$danger,
    "light": bsDefaults.$light,
    "dark": bsDefaults.$dark,
    "grey": color.$rgba-grey-5,
    "navy": color.$rgba-tertiary-0
);

//Stop nav link styles from overriding text colors
$nav-link-color: $false;
$nav-link-hover-color: $false;
$nav-link-active-color: $false;
$navbar-light-color: $false;
$navbar-light-hover-color: $false;
$navbar-light-active-color: $false;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";