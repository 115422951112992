// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.tab-group {
    display: flex;
    flex-direction: column;
  
    // No need to set list-style: none; since .tab-group-item is block level
    padding-left: 0; // reset padding because ul and ol
    margin-bottom: 0;
    @include border-radius($tab-group-border-radius);
  }
  
  .tab-group-numbered {
    list-style-type: none;
    counter-reset: section;
  
    > li::before {
      // Increments only this instance of the section counter
      content: counters(section, ".") ". ";
      counter-increment: section;
    }
  }
  
  
  // Interactive tab items
  //
  // Use anchor or button elements instead of `li`s or `div`s to create interactive
  // tab items. Includes an extra `.active` modifier class for selected items.
  
  .tab-group-item-action {
    width: 100%; // For `<button>`s (anchors become 100% by default though)
    color: $tab-group-action-color;
    text-align: inherit; // For `<button>`s (anchors inherit)
  
    // Hover state
    &:hover,
    &:focus {
      z-index: 1; // Place hover/focus items above their siblings for proper border styling
      color: $tab-group-action-hover-color;
      text-decoration: none;
      background-color: $tab-group-hover-bg;
    }
  
    &:active {
      color: $tab-group-action-active-color;
    }
  }
  
  
  // Individual tab items
  //
  // Use on `li`s or `div`s within the `.tab-group` parent.
  
  .tab-group-item {
    position: relative;
    display: block;
    padding: $tab-group-item-padding-y $tab-group-item-padding-x;
    color: $tab-group-color;
    text-decoration: if($link-decoration == none, null, none);
    border: $tab-group-border-width solid $tab-group-border-color;
    --bs-bg-opacity: 1;
    background-color: transparent !important;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
  
    &:first-child {
      @include border-top-radius(inherit);
    }
  
    &:last-child {
      @include border-bottom-radius(inherit);
    }

    &.hover,
    &:hover {
      background-image: linear-gradient(rgba(0, 0, 0, 0.05) 0 0);
    }
  
    &.disabled,
    &:disabled {
      color: $tab-group-disabled-color;
      pointer-events: none;
      --bs-bg-opacity: 1;
      background-color: transparent !important;
      background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
    }
  
    // Include both here for `<a>`s and `<button>`s
    &.active {
      z-index: 2; // Place active items above their siblings for proper border styling
      color: $tab-group-active-color;
      background-image: none;
      &.hover {
        --bs-bg-opacity: 1;
        background-color: transparent !important;
      }
    }
  
    & + & {
      border-top-width: 0;
  
      &.active {
        margin-top: -$tab-group-border-width;
        border-top-width: $tab-group-border-width;
      }
    }
  }
  
  
  // Horizontal
  //
  // Change the layout of tab group items from vertical (default) to horizontal.
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      .tab-group-horizontal#{$infix} {
        flex-direction: row;
  
        > .tab-group-item {
          &:first-child {
            @include border-bottom-start-radius($tab-group-border-radius);
            @include border-top-end-radius(0);
          }
  
          &:last-child {
            @include border-top-end-radius($tab-group-border-radius);
            @include border-bottom-start-radius(0);
          }
  
          &.active {
            margin-top: 0;
          }
  
          + .tab-group-item {
            border-top-width: $tab-group-border-width;
            border-left-width: 0;
  
            &.active {
              margin-left: -$tab-group-border-width;
              border-left-width: $tab-group-border-width;
            }
          }
        }
      }
    }
  }
  
  
  // Flush tab items
  //
  // Remove borders and border-radius to keep tab group items edge-to-edge. Most
  // useful within other components (e.g., cards).
  
  .tab-group-flush {
    @include border-radius(0);
  
    > .tab-group-item {
      border-width: 0 0 $tab-group-border-width;
  
      &:last-child {
        border-bottom-width: 0;
      }
    }
  }
  
  
  // scss-docs-start tab-group-modifiers
  // tab group contextual variants
  //
  // Add modifier classes to change text and background color on individual items.
  // Organizationally, this must come after the `:hover` states.
  
  @each $state, $value in $theme-colors {
    $tab-group-variant-bg: shift-color($value, $tab-group-item-bg-scale);
    $tab-group-variant-color: shift-color($value, $tab-group-item-color-scale);
    @if (contrast-ratio($tab-group-variant-bg, $tab-group-variant-color) < $min-contrast-ratio) {
      $tab-group-variant-color: mix($value, color-contrast($tab-group-variant-bg), abs($tab-group-item-color-scale));
    }
  
    @include tab-group-item-variant($state, $tab-group-variant-bg, $tab-group-variant-color);
  }
  // scss-docs-end tab-group-modifiers
  