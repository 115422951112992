// Tab Groups

// scss-docs-start tab-group-mixin
@mixin tab-group-item-variant($state, $background, $color) {
    .tab-group-item-#{$state} {
  
      &.tab-group-item-action {
        &:hover,
        &:focus {
          color: $background;
        }
  
        &.active {
          color: $background;
          border-bottom: none;
          border-top-width: 0.1rem;
          border-top-color: $background;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
  // scss-docs-end tab-group-mixin
  