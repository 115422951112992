// As RGBa codes */

$rgba-primary-0: rgba(255,105, 78,1);	// Main Primary color */
$rgba-primary-1: rgba(255,159,141,1);
$rgba-primary-2: rgba(255, 59, 23,1);
$rgba-primary-3: rgba(250, 78, 46,1);
$rgba-primary-4: rgba(247, 40,  2,1);
$rgba-primary-5: rgba(124, 20,1,1);
$rgba-primary-0-025: rgba(255,105, 78,0.25);

$rgba-secondary-1-0: rgba(255,178, 78,1);	// Main Secondary color (1) */
$rgba-secondary-1-1: rgba(255,206,141,1);
$rgba-secondary-1-2: rgba(255,154, 23,1);
$rgba-secondary-1-3: rgba(250,161, 46,1);
$rgba-secondary-1-4: rgba(247,140,  2,1);
$rgba-secondary-1-5: rgba(124, 79,1,1);
$rgba-secondary-1-0-025: rgba( 255,178,78,0.25);

$rgba-secondary-2-0: rgba( 57,119,164,1);
$rgba-secondary-2-1: rgba(112,159,193,1);
$rgba-secondary-2-2: rgba( 38,169,252,1);  // Main Secondary color (2) */ Changed from Palleton value slightly
$rgba-secondary-2-3: rgba( 38,110,160,1);
$rgba-secondary-2-4: rgba( 11, 97,158,1);
$rgba-secondary-2-2-025: rgba( 38,169,252,0.25);
$rgba-secondary-2-5: rgba(6,49,79,1);
$rgba-secondary-2-6: rgba(148,210,255,1);
$rgba-secondary-2-7: rgba(38, 140, 210);

$rgba-complement-0: rgba( 57,187,101,1);	// Main Complement color */
$rgba-complement-1: rgba(116,209,147,1);
$rgba-complement-2: rgba( 23,254,101,1);
$rgba-complement-3: rgba( 34,183, 84,1);
$rgba-complement-4: rgba(  1,181, 62,1);
$rgba-complement-0-025: rgba( 57,187,101,0.25);
$rgba-complement-5: rgba(1,91,31,1);

$rgba-tertiary-0: rgba( 31, 36, 97,1);	// Main Navy color */
$rgba-tertiary-1: rgba( 96,100,157,1);
$rgba-tertiary-2: rgba( 58, 63,126,1);
$rgba-tertiary-3: rgba( 12, 17, 69,1);
$rgba-tertiary-4: rgba(  4,  7, 42,1);

$rgba-grey-0: rgba(250,250,250);
$rgba-grey-1: rgb(65, 65, 65);
$rgba-grey-2: rgba(0,0,0, 0.125);
$rgba-grey-3: rgba(222, 226, 230);  //Borders
$rgba-grey-4: rgba(0,0,0,0.5);  //Nav links
$rgba-grey-5: rgba(180,180,180);
$rgba-white-0: rgba(255,255,255);
$rgba-white-1: rgba(255,255,255, 0.75);
$rgba-white-2: rgba(255, 255, 255, 0.5);