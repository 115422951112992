//https://dev.to/peterc/how-to-create-joined-bulletpoint-lists-with-css-bbc-news-style-1eem
.timeline {
    list-style-type: none;
  }

.timeline > li {
    /* You need to turn on relative positioning so the line is placed relative to the item rather than absolutely on the page */
    position: relative;
    /* Use padding to space things out rather than margins as the line would get broken up otherwise */
    margin: 0;
    padding-bottom: 1em;
    padding-left: 40px;
  }
  
  /* The actual line being placed before each list item, tweak width and color as appropriate */
  .timeline > li:before {
    background-color: $timeline-bg;
    width: 2px;
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 5px;
  }
  
  /* Start the line further down on the first list item */
  .timeline > li:first-child:before { top: 18px;  }
  
  /* Stop the line short on the final list item */
  .timeline > li:last-child:before { height: 6px; }
  
  .badge-inline {
    top: -5px;
    margin-right: 3px;
  }
  
  .timeline > li > .bullet {
    position: absolute; 
    left: 6px;
    margin-top: 2px;
    margin-left: 0px; 
    width: 20px; 
    background-color: $timeline-bg; 
    float: left; 
    padding-right: 8px;
    transform: translateX(-50%);
  }  
  
  // scss-docs-start tab-group-modifiers
  // tab group contextual variants
  //
  // Add modifier classes to change text and background color on individual items.
  // Organizationally, this must come after the `:hover` states.
  
  @each $state, $value in $theme-colors {
    $timeline-variant-bg: shift-color($value, $timeline-bg-scale);
    $timeline-variant-color: shift-color($value, $timeline-color-scale);
    @if (contrast-ratio($timeline-variant-bg, $timeline-variant-color) < $min-contrast-ratio) {
      $timeline-variant-color: mix($value, color-contrast($timeline-variant-bg), abs($timeline-color-scale));
    }
  
    @include timeline-variant($state, $timeline-variant-bg, $timeline-variant-color);
  }
  // scss-docs-end tab-group-modifiers